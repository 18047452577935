import React, { useEffect, useState } from 'react'
import { Header,HeaderBox,Footer } from '../../components/imports'
import { APP_NAME, AVATAR_ROOT, PHOTOS_ROOT, WEBROOT } from '../../constants/general'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { AsideClient } from '../../components/Aside';
import Annonces from '../../class/Annonces';
import Moment from 'react-moment';
import { ArtcilePanierClient } from '../../components/Articles';
import { useTranslation } from 'react-i18next';

function Commandes() {

    const {t} = useTranslation()
    
    const url = useParams()

    const [loading, setLoading] = useState(true)

    const [achats, setAchats] = useState([])

    const getAchats = async (stt) => {

      setAchats([])

      setLoading(true)
        
      const results = await Annonces.achats(JSON.stringify({stt: stt, limit: '30'}))

      setLoading(false)

      setAchats(results.data)

    }

    useEffect(() => {

        document.title = t('t278') + " - " + APP_NAME

        getAchats(url.url)
    
    }, [url])

  return (

    <div>

      <Header/>

      <HeaderBox/>

      <div className='mt80 c m_mt_0'>

        <div className="flex ">

            <AsideClient/>

            <div className='col-10 p20m'>

                <h1 className='ff fz2 mb30 cl8'><span className="icon mr10">m</span>{ t('t8') }</h1>

                <div className='mb40 m_list_elem'>
                    <Link to={'/commandes/0'} className={`btn cl1 hover-bg3 ${url.url == 0 ? " bg3" : "bg5"}`}>{ t('t279') }</Link>
                    <Link to={'/commandes/2'} className={`btn cl1 hover-bg3 ${url.url == 2 ? " bg3" : "bg5"}`}>{ t('t280') }</Link>
                    <Link to={'/commandes/3'} className={`btn cl1 hover-bg3 ${url.url == 3 ? " bg3" : "bg5"}`}>{ t('t281') }</Link>
                    <Link to={'/commandes/4'} className={`btn cl1 hover-bg3 ${url.url == 4 ? " bg3" : "bg5"}`}>{ t('t282') }</Link>
                    <Link to={'/commandes/1'} className={`btn cl1 hover-bg3 ${url.url == 1 ? " bg3" : "bg5"}`}>{ t('t283') }</Link>
                    <Link to={'/commandes/5'} className={`btn cl1 hover-bg3 ${url.url == 5 ? " bg3" : "bg5"}`}>{ t('t284') }</Link>
                </div>

                { loading && <div className='flex jc-center mt50 mb50'><div className="lds-dual-ring col"></div></div> }

                { achats && achats.map(achat => <ArtcilePanierClient achat={achat} key={achat.idP}/> ) }

            </div>

        </div>
    </div>
    <Footer/>
  </div>

  )
}

export default Commandes